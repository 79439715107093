import $ from "jquery";
import "jquery-mousewheel";
import "drawsvg";
import "selectric";

let disableScrolling = true;

import "./pace.js";
import "./animate.js";

$('.dealer_head .close_modal').click(function(e) {
    $('#dealer_modal_box').removeClass('active');
});

$('.buy_tire').click(function(e) {
    e.preventDefault();
    $('#dealer_modal_box').addClass('active');
    $('#tire_name').val($(this).data('tire_name'));
    $('.dealer_result a').css('display', 'none').filter('[data-city_id=1][data-tire_name=' + $('#tire_name').val() + ']').css('display', 'block');
});

$('#dealer_modal_box').click(function(event) {
    if (!$(event.target).closest(".dealer_modal").length) {
        $('#dealer_modal_box').removeClass('active');
    }
});

$(function() {
    $('#city_select select').selectric();
});

$('#city_select select').on('change', function() {
    $('.dealer_result a').css('display', 'none').filter('[data-city_id=' + $(this).val() + '][data-tire_name=' + $('#tire_name').val() + ']').css('display', 'block');
});

Pace.on('hide', function() {
    $('.mouse_icon').fadeIn();
    $('#loader').fadeOut();
    disableScrolling = false;
});

/* Animation blocks */

/* Тут рендер нав панели справа */
function render_nav() {

    let nav_height = $('.nav_panel').height();
    let screen = $(window).width(); //для адаптива понадобится
    let nav_part = nav_height / 7;
    let buttons = '';
    let i;
    for (i = 0; i < 8; i++) {
        let offset = nav_part * i - 7;
        let active = '';
        if (i == 0) {
            active = 'active'
        }
        let button = '<button id="section_' + i + '" class="nav_panel_btn ' + active + '" style="top:' + offset + 'px" data-key="' + i + '"></button>';
        buttons += button;

    }

    $(".nav_panel_box").html(buttons);

    let scale = screen / 1920;

    let finish_scale = 0.9;

    if (scale <= 1) {
        finish_scale = scale - 0.1;
    }

    $('.zoom_block').css({
        'transform': 'scale(' + finish_scale + ')',
    })
}

render_nav();

$(window).on('resize', function() {
    $("#section_0").trigger("click");
    setTimeout(function() {
        render_nav();
    }, 300);
});

$(function() {

    window.onload = function() {
        $('.nav_panel').addClass('active');
        $('.tire_block').removeClass('hide_tire_block');

        let car_array = [];
        let wheel_sp = [];
        let wheel_sp_reverse = [];
        let wheel_grand = [];
        let wheel_grand_reverse = [];
        let wheel_canava = [];
        let wheel_canava_reverse = [];
        let wheel_bok = [];
        let wheel_bok_reverse = [];

        for (let k = 0; k < 61; k++) {
            car_array.push(k);
        }

        /*wheel sprite base*/

        for (let j = 0; j < 22; j++) {
            wheel_sp.push(j);
        }

        for (let j = 21; j >= 0; j--) {
            wheel_sp_reverse.push(j);
        }

        for (let j = 21; j < 34; j++) {
            wheel_grand.push(j);
        }

        for (let j = 33; j >= 21; j--) {
            wheel_grand_reverse.push(j);
        }

        for (let j = 33; j < 43; j++) {
            wheel_canava.push(j);
        }

        for (let j = 42; j >= 33; j--) {
            wheel_canava_reverse.push(j);
        }

        for (let j = 42; j < 53; j++) {
            wheel_bok.push(j);
        }

        for (let j = 52; j >= 42; j--) {
            wheel_bok_reverse.push(j);
        }

        /*wheel sprite base end */

        $(".car_animation").animateSprite({
            fps: 30,
            animations: {
                car: car_array,
            },
            loop: true,
            autoplay: false,
            complete: function() {
                // use complete only when you set animations with 'loop: false'
            }
        });
        $(".wheel_animation").animateSprite({
            fps: 21,
            animations: {
                //wheel_rotate: wheel_rotate,
                wheel_sp: wheel_sp,
                wheel_sp_reverse: wheel_sp_reverse,
                wheel_grand: wheel_grand,
                wheel_grand_static: [33],
                wheel_grand_reverse: wheel_grand_reverse,
                wheel_canava: wheel_canava,
                wheel_canava_reverse: wheel_canava_reverse,
                wheel_bok: wheel_bok,
                wheel_bok_reverse: wheel_bok_reverse,
            },
            loop: false,
            autoplay: false,
            complete: function() {
                // use complete only when you set animations with 'loop: false'
            }
        });

        $('.wheel_animation_solo').animateSprite({
            fps: 24,
            animations: {
                wheel_rotate: [0, 1, 2],
            },
            loop: true,
            autoplay: false,
            complete: function() {
                // use complete only when you set animations with 'loop: false'
            }
        });

        $('.wheel_animation_crus').animateSprite({
            fps: 18,
            animations: {
                wheel_rotate_crus: [0, 1, 2, 3],
            },
            loop: true,
            autoplay: false,
            complete: function() {
                // use complete only when you set animations with 'loop: false'
            }
        });

        //
    };

    //

    $('.first_slide_block .get_tire').click(function(e) {
        e.preventDefault();
        $("#section_7").trigger("click");
    });

    $('.right_side_fixed_box .close').click(function(e) {
        $('.right_side_fixed').removeClass('active');
    });

    $('#nano').click(function(e) {
        e.preventDefault();
        $('.right_side_fixed').removeClass('active');
        $('.right_side_text-nano').addClass('active');
    });
    $('#flat').click(function(e) {
        e.preventDefault();
        $('.right_side_fixed').removeClass('active');
        $('.right_side_text-flat').addClass('active');
    });
    $('#equilidrium').click(function(e) {
        e.preventDefault();
        $('.right_side_fixed').removeClass('active');
        $('.right_side_text-equilidrium').addClass('active');
    });
    $('#silence').click(function(e) {
        e.preventDefault();
        $('.right_side_fixed').removeClass('active');
        $('.right_side_text-silence').addClass('active');
    });
    //graph svg
    let red_line = $('#red_line').drawsvg({
        duration: 2500,
        easing: 'linear',
    });
    let yellow_line = $('#yellow_line').drawsvg({
        duration: 2500,
        easing: 'linear',
    });
    let step_10 = $('#step_10').drawsvg({
        duration: 1000,
        easing: 'linear',
        stagger: 300,
    });
    $(".legend_item_yellow").hover(
        function() {
            $('.red_line').css({
                'opacity': '0',
            });
        }, function() {
            $('.red_line').css({
                'opacity': '1',
            });
        }
    );
    $(".legend_item_red").hover(
        function() {
            $('.yellow_line').css({
                'opacity': '0',
            });
        }, function() {
            $('.yellow_line').css({
                'opacity': '1',
            });
        }
    );
    /* Тут всякие анимации скролинга и переходы */
    let scroll_index = 0;
    let active_block = '';
    let disable_time = 600;

    $('.dunlop_container').on('mousewheel', function(event) {
        event.preventDefault();

        if (disableScrolling) {
            return;
        }

        if (scroll_index > 6) {
            disable_time = 900;
        } else {
            disable_time = 600;
        }

        disableScrolling = true;
        setTimeout(function() {
            disableScrolling = false;
        }, disable_time);

        //console.log(event.deltaX, event.deltaY, event.deltaFactor); detect up or down move

        if (event.deltaY < 0 && scroll_index < 14) {
            scroll_index++;
        } else if (event.deltaY > 0 && scroll_index > 0) {
            scroll_index--;
        }

        if (scroll_index == 7 && event.deltaY < 0) {
            scroll_index = 8;
        } else if (scroll_index == 7 && event.deltaY > 0) {
            scroll_index = 6;
        }

        //console.log(scroll_index); slide index here
        draw_panel_on_wheel(scroll_index, event.deltaY);
        $('.right_side_fixed').removeClass('active'); //autohide right sidebar
        switch(scroll_index) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                move_to_0(scroll_index, event.deltaY);
                break;
            case 8:
                move_to_1(scroll_index, event.deltaY);
                break;
            case 9:
                move_to_2(scroll_index, event.deltaY);
                break;
            case 10:
                move_to_3(scroll_index, event.deltaY);
                break;
            case 11:
                move_to_4(scroll_index, event.deltaY);
                break;
            case 12:
                move_to_5(scroll_index, event.deltaY);
                break;
            case 13:
                move_to_6(scroll_index, event.deltaY);
                break;
            case 14:
                move_to_7(scroll_index, event.deltaY);
                break;
            default:
                return false;
        }

    });
    $(".mouse_icon").click(function() {
        $('.mouse_icon').addClass('opacity_0');
        $('.get_tire').addClass('get_tire_out');

        $('.ice_text').addClass('step_1');
        $('.ice_text_2').addClass('step_1');

        draw_panel_on_wheel(1, -1);
    });

    /*Ивенты для панели*/
    $('body').on('click', '.nav_panel_btn', function() {
        let nav_height = $('.nav_panel').height();
        let nav_part = nav_height / 7;
        let index = $(this).index();

        $('.nav_panel_box_hover').css({'height': nav_part * index + 'px'});
        let i;
        for (i = 0; i < index + 1; i++) {
            let id = i;
            setTimeout(function() {
                $('.nav_panel_btn').eq(id).addClass('active');
            }, 400 * i / index);
        }

        $($('.nav_panel_btn').get().reverse()).each(function(key) {
            let item = $(this);
            setTimeout(function() {
                if (item.data('key') > index) {
                    $('.nav_panel_btn').eq(item.data('key')).removeClass('active');
                }
            }, 100 * key);
        });
        let key = $(this).data('key');

        if (key > 1) {
            $('.nav_panel').addClass('grey_line');
        } else {
            $('.nav_panel').removeClass('grey_line');
        }
        switch(key) {
            case 0:
                scroll_index = 0;
                move_to_0(0, 1);
                break;
            case 1:
                scroll_index = 8;
                move_to_1();
                break;
            case 2:
                scroll_index = 9;
                move_to_2();
                break;
            case 3:
                scroll_index = 10;
                move_to_3();
                break;
            case 4:
                scroll_index = 11;
                move_to_4();
                break;
            case 5:
                scroll_index = 12;
                move_to_5();
                break;
            case 6:
                remove_graph();
                scroll_index = 13;
                move_to_6();
                break;
            case 7:
                scroll_index = 14;
                move_to_7();
                break;
            default:
                break;
        }
    });

    function move_to_0(scroll_index, direction) {

        //animate by scroll

        $('.car_animation').animateSprite('resume', 'car');
        setTimeout(function() {
            $('.car_animation').animateSprite('stop');
        }, 400);

        $('.wheel_animation_solo').animateSprite('resume', 'wheel_rotate');
        setTimeout(function() {
            $('.wheel_animation_solo').animateSprite('stop');
        }, 500);

        //animate by scroll end

        hide_step_10();
        hide_step_11();
        hide_moved([2, 3, 4]);
        show_hide_left_fixed(0);
        hide_car();

        $('.ice_text').addClass('step_0');
        $('.ice_text_2').addClass('step_0');
        $('.wheel_animation_block .animation_box').addClass('step_0');
        $('.ice_animation_block').addClass('step_0');

        $('.mouse_icon').show().removeClass('opacity_0');
        $('.get_tire').removeClass('get_tire_out');

        $('.white_sub_bg').removeClass('step_7');

        $('.white_bg_rotate').removeClass('rotate_180').removeClass('rotate_360');

        $('.video_box').removeClass('video_hide');

        if (!$('.dunlop_container').hasClass('moved_1')) {
            //console.log('step_7_up');
            $('.wheel_animation').animateSprite('play', 'wheel_sp_reverse');
            setTimeout(function() {
                scroll_to('block_ice');
                setTimeout(function() {
                    $('.wheel_animation_block').css({
                        'z-index': 2,
                    });
                }, 400);
            }, 600);

            $('.dunlop_container').addClass('moved_1');
        }

        if (scroll_index == 0) {

            $('.mouse_icon').show().removeClass('opacity_0');
            $('.get_tire').removeClass('get_tire_out');
        } else {
            $('.mouse_icon').addClass('opacity_0');
            setTimeout(function() {
                $('.mouse_icon').hide();
            }, 300);
            $('.get_tire').addClass('get_tire_out');
        }

        left_right_text_remove();
        hide_circle();

        if (scroll_index == 6 && direction > 0) {

            setTimeout(function() {
                remove_steps();
                $('.ice_text').addClass('step_' + scroll_index);
                $('.ice_text_2').addClass('step_' + scroll_index);

                $('.wheel_animation_block .animation_box').addClass('step_' + scroll_index);
                $('.ice_animation_block').addClass('step_' + scroll_index);

                setTimeout(function() {
                    $('.white_sub_bg').removeClass('step_7');
                }, 300);

            }, 1000);

        } else {
            remove_steps();

            $('.ice_text').addClass('step_' + scroll_index);
            $('.ice_text_2').addClass('step_' + scroll_index);

            $('.wheel_animation_block .animation_box').addClass('step_' + scroll_index);
            $('.ice_animation_block').addClass('step_' + scroll_index);

        }
    };

    function move_to_1(scroll_index, direction) {
        hide_step_10();
        hide_step_11();
        remove_steps();
        hide_moved([1, 3, 4]);
        show_hide_left_fixed(0);
        show_circle();
        hide_car();

        $('.get_tire').addClass('get_tire_out');
        $('.ice_text').addClass('step_7');
        $('.ice_text_2').addClass('step_7');
        $('.wheel_animation_block .animation_box').addClass('step_8');
        $('.ice_animation_block').addClass('step_8');

        $('.video_box').removeClass('video_hide');

        $('.mouse_icon').addClass('opacity_0').hide();

        /*end переход */

        /*переход на анимацию шины*/
        $('.wheel_animation_block').css({
            'z-index': 12,
        });

        $('.nav_panel').removeClass('grey_line');

        $('.left_text_movable').each(function(index) {
            let item = $(this);
            setTimeout(function() {
                item.addClass('left_side_show');
            }, 200 * index);
        });

        $('.right_text_movable').each(function(index) {
            let item = $(this);
            setTimeout(function() {
                item.removeClass('right_side_show');
            }, 150 * index);
        });

        $('.white_sub_bg').addClass('step_7');

        $('.wheel_animation_solo').animateSprite('stop');
        if (direction < 0) {
            //Значит вниз скролим
            // console.log('step_8_down');
            $('.wheel_animation').animateSprite('play', 'wheel_sp');
            setTimeout(function() {

                if (!$('.dunlop_container').hasClass('moved_2')) {
                    scroll_to('block_video');
                    $('.dunlop_container').addClass('moved_2')
                }

            }, 300);
        } else {
            // console.log('step_8_up');
            if (!$('.dunlop_container').hasClass('moved_2')) {
                scroll_to('block_video');
                $('.dunlop_container').addClass('moved_2')
            }
            $('.wheel_animation').animateSprite('play', 'wheel_grand_reverse');
            $('.video_bg_5').removeClass('video_hide');
            setTimeout(function() {
                $('.white_bg_rotate').removeClass('rotate_360').removeClass('rotate_180');

            }, 400);
        }

    };

    function move_to_2(scroll_index, direction) {
        hide_step_10();
        hide_step_11();
        remove_steps();
        hide_moved([1, 3, 4]);
        show_hide_left_fixed(0);
        hide_car();

        $('.wheel_animation_block').css({
            'z-index': 12,
        });

        $('.wheel_animation_block .animation_box').addClass('step_8');
        $('.ice_animation_block').addClass('step_8');

        if (!$('.dunlop_container').hasClass('moved_2')) {
            scroll_to('block_video');
            $('.dunlop_container').addClass('moved_2')
        }

        $('.ice_text').addClass('step_7');
        $('.ice_text_2').addClass('step_7');

        $('.mouse_icon').addClass('opacity_0').hide();

        $('.get_tire').addClass('get_tire_out');

        $('.circle').addClass('show').addClass('rotate_circle');
        $('.tire_block').addClass('show');

        active_block = '2';
        $('.nav_panel').addClass('grey_line');

        if (direction < 0) {
            // console.log('step_9_down');
            $('.wheel_animation').animateSprite('play', 'wheel_grand');

            $('.white_bg_rotate').removeClass('rotate_360').addClass('rotate_180');
            setTimeout(function() {
                $('.video_bg_5').addClass('video_hide');
                $('.video_bg_4').removeClass('video_hide');
            }, 600);
        } else {
            // console.log('step_9_up');
            $('.wheel_animation').animateSprite('play', 'wheel_grand_static');

            $('.white_bg_rotate').removeClass('rotate_360').addClass('rotate_180');
            setTimeout(function() {
                $('.video_bg_5').addClass('video_hide');
                $('.video_bg_4').removeClass('video_hide');
            }, 600);
        }

        $('.left_text_movable').each(function(index) {
            let item = $(this);
            setTimeout(function() {
                item.removeClass('left_side_show');
            }, 200 * index);
        });

        $('.right_text_movable').each(function(index) {
            let item = $(this);
            setTimeout(function() {
                item.addClass('right_side_show');
            }, 200 * index);
        });
    };

    function move_to_3(scroll_index, direction) {
        show_step_10();
        hide_step_11();
        remove_steps();
        hide_moved([1, 3, 4]);
        left_right_text_remove();
        hide_car();

        $('.wheel_animation_block').css({
            'z-index': 12,
        });

        $('.wheel_animation_block .animation_box').addClass('step_8');
        $('.ice_animation_block').addClass('step_8');

        if (!$('.dunlop_container').hasClass('moved_2')) {
            scroll_to('block_video');
            $('.dunlop_container').addClass('moved_2')
        }

        $('.ice_text').addClass('step_7');
        $('.ice_text_2').addClass('step_7');

        $('.mouse_icon').addClass('opacity_0').hide();
        $('.get_tire').addClass('get_tire_out');

        $('.circle').addClass('show').removeClass('rotate_circle');
        $('.tire_block').addClass('show');

        if (direction < 0) {
            //  console.log('step_10_down');
            $('.wheel_animation').animateSprite('play', 'wheel_grand_static');
            $('.video_bg_4').addClass('video_hide');
            $('.video_bg_3').removeClass('video_hide');

            setTimeout(function() {
                $('.white_bg_rotate').removeClass('rotate_180').addClass('rotate_360');
                show_hide_left_fixed(2);
            }, 400);
        } else {
            // console.log('step_10_up');
            show_hide_left_fixed(2);
            $('.wheel_animation').animateSprite('play', 'wheel_canava_reverse');
            $('.video_bg_4').addClass('video_hide');
            $('.video_bg_3').removeClass('video_hide');
            $('.white_bg_rotate').removeClass('rotate_180').addClass('rotate_360');
        }

    };

    function move_to_4(scroll_index, direction) {
        hide_step_10();
        show_step_11();
        remove_steps();
        hide_moved([1, 3, 4]);
        left_right_text_remove();
        show_hide_left_fixed(3);
        hide_car();

        document.getElementById("video_2").play();

        $('.wheel_animation_block').css({
            'z-index': 12,
        });

        $('.wheel_animation_block .animation_box').addClass('step_8');
        $('.ice_animation_block').addClass('step_8');

        if (!$('.dunlop_container').hasClass('moved_2')) {
            scroll_to('block_video');
            $('.dunlop_container').addClass('moved_2')
        }

        $('.ice_text').addClass('step_7');
        $('.ice_text_2').addClass('step_7');

        $('.video_box').removeClass('video_hide');

        $('.white_bg_rotate').removeClass('rotate_180').addClass('rotate_360');

        $('.video_bg_5').addClass('video_hide');
        $('.video_bg_4').addClass('video_hide');
        $('.video_bg_3').addClass('video_hide');
        $('.video_bg_2').removeClass('video_hide');

        $('.mouse_icon').addClass('opacity_0').hide();

        $('.get_tire').addClass('get_tire_out');

        $('.circle').removeClass('rotate_circle').addClass('show');
        $('.tire_block').addClass('show');

        if (direction < 0) {
            //  console.log('step_11_down');
            $('.wheel_animation').animateSprite('play', 'wheel_canava');
        } else {
            $('.wheel_animation').animateSprite('play', 'wheel_bok_reverse');
            //  console.log('step_11_up');
        }
    };

    function move_to_5(scroll_index, direction) {
        hide_step_10();
        hide_step_11();
        $('.ice_animation_block').addClass('step_8');
        hide_moved([1, 3, 4]);
        left_right_text_remove();
        show_hide_left_fixed(4);
        show_car();

        $('.wheel_animation_block').css({
            'z-index': 12,
        });

        if (!$('.dunlop_container').hasClass('moved_2')) {
            scroll_to('block_video');
            $('.dunlop_container').addClass('moved_2')
        }

        $('.ice_text').addClass('step_7');
        $('.ice_text_2').addClass('step_7');

        $('.video_box').removeClass('video_hide');

        $('.white_bg_rotate').removeClass('rotate_180').addClass('rotate_360');

        $('.video_bg_5').addClass('video_hide');
        $('.video_bg_4').addClass('video_hide');
        $('.video_bg_3').addClass('video_hide');
        $('.video_bg_2').addClass('video_hide');
        $('.video_bg_1').removeClass('video_hide');

        $('.mouse_icon').addClass('opacity_0').hide();

        $('.get_tire').addClass('get_tire_out');

        $('.circle').removeClass('rotate_circle').addClass('show');
        $('.tire_block').removeClass('show');

        if (direction < 0) {
            //  console.log('step_12_down');
            $('.wheel_animation').animateSprite('play', 'wheel_bok');
            setTimeout(function() {
                for (let i = 0; i < 10; i++) {
                    $('.wheel_animation_block .animation_box').removeClass('step_' + i);
                }
                $('.wheel_animation_block .animation_box').addClass('step_9');
                $('.wheel_animation_crus').animateSprite('play', 'wheel_rotate_crus');
            }, 400);

        } else {
            for (let i = 0; i < 10; i++) {
                $('.wheel_animation_block .animation_box').removeClass('step_' + i);
            }
            $('.wheel_animation_block .animation_box').addClass('step_9');
            $('.wheel_animation_crus').animateSprite('play', 'wheel_rotate_crus');
            // console.log('step_12_up');
        }

    };

    function move_to_6(scroll_index, direction) {
        hide_step_10();
        hide_step_11();
        remove_steps();
        hide_moved([2, 1, 4]);
        left_right_text_remove();
        show_hide_left_fixed(0);
        hide_car();

        $('.wheel_animation_block').css({
            'z-index': 12,
        });

        $('.wheel_animation_block .animation_box').addClass('step_8');
        $('.ice_animation_block').addClass('step_8');

        if (!$('.dunlop_container').hasClass('moved_3')) {
            scroll_to('block_lines');
            $('.dunlop_container').addClass('moved_3')
        }

        $('.ice_text').addClass('step_7');
        $('.ice_text_2').addClass('step_7');

        $('.video_box').removeClass('video_hide');

        $('.white_bg_rotate').removeClass('rotate_180').addClass('rotate_360');

        $('.video_bg_5').addClass('video_hide');
        $('.video_bg_4').addClass('video_hide');
        $('.video_bg_3').addClass('video_hide');
        $('.video_bg_2').addClass('video_hide');
        $('.video_bg_1').addClass('video_hide');

        $('.mouse_icon').addClass('opacity_0').hide();

        $('.get_tire').addClass('get_tire_out');

        $('.circle').removeClass('rotate_circle').addClass('show');
        $('.tire_block').addClass('show');

        remove_graph();

        red_line.drawsvg('animate');
        setTimeout(function() {
            $('.red_line').addClass('active');
        }, 1500);

        setTimeout(function() {
            yellow_line.drawsvg('animate');
            setTimeout(function() {
                $('.yellow_line').addClass('active');
            }, 1500);
        }, 400);

    };

    function move_to_7(scroll_index, direction) {
        hide_step_10();
        hide_step_11();
        remove_steps();
        hide_moved([2, 3, 1]);
        left_right_text_remove();
        show_hide_left_fixed(0);
        hide_car();

        $('.wheel_animation_block').css({
            'z-index': 12,
        });

        $('.wheel_animation_block .animation_box').addClass('step_8');
        $('.ice_animation_block').addClass('step_8');

        if (!$('.dunlop_container').hasClass('moved_4')) {
            scroll_to('block_last');
            $('.dunlop_container').addClass('moved_4')
        }

        $('.ice_text').addClass('step_7');
        $('.ice_text_2').addClass('step_7');

        $('.video_box').removeClass('video_hide');

        $('.white_bg_rotate').removeClass('rotate_180').addClass('rotate_360');

        $('.video_bg_5').addClass('video_hide');
        $('.video_bg_4').addClass('video_hide');
        $('.video_bg_3').addClass('video_hide');
        $('.video_bg_2').addClass('video_hide');
        $('.video_bg_1').addClass('video_hide');

        $('.mouse_icon').addClass('opacity_0').hide();

        $('.get_tire').addClass('get_tire_out');

        $('.circle').removeClass('rotate_circle').addClass('show');
        $('.tire_block').addClass('show');

    };

    function draw_panel_on_wheel(step, direction) {
        let nav_height = $('.nav_panel').height();
        let nav_part = nav_height / 7;
        let index = 0;

        if (step == 7 && direction < 0) {
            step = 8;
        } else if (step == 7 && direction > 0) {
            step = 6;
        }
        switch(step) {
            case 0:
                index = 0;
                break;
            case 1:
                index = 0.15;
                break;
            case 2:
                index = 0.30;
                break;
            case 3:
                index = 0.45;
                break;
            case 4:
                index = 0.6;
                break;
            case 5:
                index = 0.75;
                break;
            case 6:
                index = 0.8;
                if (direction < 0) {
                    setTimeout(function() {
                        set_active_panel_btn(index);
                    }, 400);
                } else {
                    set_active_panel_btn(index);
                }
                break;
            case 7:
                index = 0.9;
                if (direction < 0) {
                    setTimeout(function() {
                        set_active_panel_btn(index);
                    }, 400);
                } else {
                    set_active_panel_btn(index);
                }
                break;
            case 8:
                index = 1;
                if (direction < 0) {
                    setTimeout(function() {
                        set_active_panel_btn(index);
                    }, 400);
                } else {
                    set_active_panel_btn(index);
                }

                break;
            case 9:
                index = 2;
                if (direction < 0) {
                    setTimeout(function() {
                        set_active_panel_btn(index);
                    }, 400);
                } else {
                    set_active_panel_btn(index);
                }
                break;
            case 10:
                index = 3;
                if (direction < 0) {
                    setTimeout(function() {
                        set_active_panel_btn(index);
                    }, 400);
                } else {
                    set_active_panel_btn(index);
                }
                break;
            case 11:
                index = 4;
                if (direction < 0) {
                    setTimeout(function() {
                        set_active_panel_btn(index);
                    }, 400);
                } else {
                    set_active_panel_btn(index);
                }
                break;
            case 12:
                index = 5;
                if (direction < 0) {
                    setTimeout(function() {
                        set_active_panel_btn(index);
                    }, 400);
                } else {
                    set_active_panel_btn(index);
                }
                break;
            case 13:
                index = 6;
                if (direction < 0) {
                    setTimeout(function() {
                        set_active_panel_btn(index);
                    }, 400);
                } else {
                    set_active_panel_btn(index);
                }
                break;
            case 14:
                index = 7;
                if (direction < 0) {
                    setTimeout(function() {
                        set_active_panel_btn(index);
                    }, 400);
                } else {
                    set_active_panel_btn(index);
                }
                break;
            default:
                return false;
                break;
        }

        $('.nav_panel_box_hover').css({'height': nav_part * index + 'px'});
    }

    function set_active_panel_btn(index) {
        if (index < 1) {
            index = 0;
        }
        $('.nav_panel_btn[data-key="' + index + '"]').addClass('active');
        $('.nav_panel_btn:gt(' + index + ')').removeClass('active');
    }

    function scroll_to(id) {
        document.getElementById(id).scrollIntoView({
            block: 'start',
            behavior: 'smooth'
        });
    }

    function remove_graph() {
        red_line.drawsvg('progress', 0);
        yellow_line.drawsvg('progress', 0);
        $('.red_line').removeClass('active');
        $('.yellow_line').removeClass('active');
    }

    function hide_step_10() {
        $('.step_10').removeClass('step_10_active');
        $('#step_10 .st0').removeClass('active');
        $('.wheel_animation_box').removeClass('step_10');
    }

    function show_step_10() {
        setTimeout(function() {

            $('.wheel_animation_box').addClass('step_10');
            setTimeout(function() {
                step_10.drawsvg('animate');

                $('.step_10').addClass('step_10_active');
                setTimeout(function() {
                    $('#step_10 .st0').addClass('active');
                }, 1200);
            }, 400);

        }, 400);
    }

    function show_step_11() {
        setTimeout(function() {
            $('#step_11').addClass('active');
        }, 500);
    }

    function hide_step_11() {
        $('#step_11').removeClass('active');
    }

    function remove_steps() {
        for (let i = 0; i < 11; i++) {
            $('.ice_text').removeClass('step_' + i);
            $('.ice_text_2').removeClass('step_' + i);
            $('.wheel_animation_block .animation_box').removeClass('step_' + i);
            $('.ice_animation_block').removeClass('step_' + i);
        }
    }

    function hide_moved(classes) {
        $.each(classes, function(index, value) {
            $('.dunlop_container').removeClass('moved_' + value);
        });
    }

    function left_right_text_remove() {
        $('.left_text_movable').each(function(index) {
            let item = $(this);
            setTimeout(function() {
                item.removeClass('left_side_show');
            }, 200 * index);
        });

        $('.right_text_movable').each(function(index) {
            let item = $(this);
            setTimeout(function() {
                item.removeClass('right_side_show');
            }, 150 * index);
        });
    }

    function show_hide_left_fixed(index) {
        $('.left_side_fixed').not('.left_side_text-' + index).each(function(index) {

            let block = $(this);
            block.addClass('last_plan');
            block.find('.left_hide_item').each(function(index) {
                let item = $(this);
                if (item.hasClass('pre_title')) {
                    item.addClass('left_hided');
                } else if (item.hasClass('title')) {
                    setTimeout(function() {
                        item.addClass('left_hided');
                    }, 100);
                } else {
                    setTimeout(function() {
                        item.addClass('left_hided');
                    }, 200);
                }
            });
        });
        $('.left_side_text-' + index).removeClass('last_plan');
        $('.left_side_text-' + index + ' .left_hide_item').each(function(index) {
            let item = $(this);
            setTimeout(function() {
                item.removeClass('left_hided');
            }, 300 * index);
        });
    }

    function show_circle() {
        $('.circle').addClass('show');
        $('.tire_block').addClass('show');
        setTimeout(function() {
            $('.circle').addClass('rotate_circle');
        }, 400);
    }

    function hide_circle() {
        $('.circle').removeClass('rotate_circle');
        setTimeout(function() {
            $('.circle').removeClass('show');
            setTimeout(function() {
                $('.tire_block').removeClass('show');
            }, 200);
        }, 300);
    }

    function show_car() {
        setTimeout(function() {
            $('#step_12').addClass('active');
        }, 400);

    }

    function hide_car() {
        $('#step_12').removeClass('active');
        $('.wheel_animation_crus').animateSprite('stop');
    }

});

