import $ from 'jquery';

$(function() {
    /* pixels events */
    $('body').on('click', '.tire_btn_black, .tire_btn_yellow, .get_tire_out', function() {
        let y = {
            type: "CONVERSION",
            /* config START */
            site_id: "5583",
            order: [],
            transaction_id: "",
            revenue: "",
            pixel_id: "Dunlop_podobrat"
            /* config END */
        };
        __GetI.push(y);
    });
    $('body').on('click', '.tire_btn_red, .get_tire_but, .buy_tire', function() {
        let k = {
            type: "CONVERSION",
            /* config START */
            site_id: "5583",
            order: [],
            transaction_id: "",
            revenue: "",
            pixel_id: "Dunlop_kupit"
            /* config END */
        };
        __GetI.push(k);
    });

    /* dealer click*/
    $('body').on('click', '.dealer_result_item', function() {

        let dealer = $(this);
        let dealer_name = dealer.text().replace(/\s+/g, " ");
        let tire_name = dealer.data('tire_name');
        let city = $('#city_select .selectric .label').text();
        let data = {
            route: 'event/add',
            city: city,
            dealer_name: dealer_name,
            tire_name: tire_name
        };

        $.ajax({
            type: 'GET',
            url: '/modal',
            data: data,
            success: function(response) {
                //console.log(response);
            }
        });

    });

});
